a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
}

html { font-size: 14px; }

@media (min-width: 768px) {
    html { font-size: 16px; }
}

.box-shadow { box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05); }

.pointer {
    cursor: pointer;
}

.item {margin: 0.5em;}

.logo-small {
    height: 35px;
    width: 35px;
    display: block
}
